.footer{
    background-color: #222;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 30px;
    text-align: center;
    p{
        color: #fff;
    }
    img{
        width: 140px;
        height: 140px;
        margin-top: -25px;
    }
    .copyRight{
        padding: 10px 80px;
        width: 80%;
        margin: auto;
        color: gray !important;
        p{
            color: #6ca742 !important;

        }
        hr{
            border-top: 1px solid lightgray;
            width: 100%;
        }
        span{
            float: left;
        }
    }
}