::-webkit-scrollbar {
    width: 12px; /* Width of the vertical scrollbar */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #266562; /* Color of the thumb (the moving part) */
  }
  
  /* For Firefox */
  ::-moz-scrollbar {
    width: 12px; /* Width of the vertical scrollbar */
  }
  
  ::-moz-scrollbar-thumb {
    background: #266562; /* Color of the thumb (the moving part) */
  }
  
  /* For IE and Edge */
  ::-ms-scrollbar {
    width: 12px; /* Width of the vertical scrollbar */
  }
  
  ::-ms-scrollbar-thumb {
    background: #266562; /* Color of the thumb (the moving part) */
  }

  .icon-top-top{
    color: #fff;
    border-radius: 50%;
    background-color: #266562;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    text-align: center;
    cursor: pointer;
    padding: 5px;
  }
  .shoshoww{
    display: block;
  }
  .nonon{
    display: none;
  }