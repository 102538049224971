.header{
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assits/about-4.jpg') center no-repeat;
    height: 100vh;
    background-size: cover;
   .content { 
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 80vh;
    h1{
        font-size: 60px;
        font-weight: 800;
        max-width: 70%;
        color: lightgray;
        @media (max-width: 750px) {
            font-size: 25px;
        }
        p{
            color: #6ca742;
            margin: 0;
        }
        span{
            color: #6ca742;
            margin: 0;
        }
    }
    }
  
}