.navnav{
    img{
        width: 100px;
        height: 80px;
    }
    .nav-link:focus, .nav-link:hover {
        color:#18D26E;
    }
    span,a{
        color: #fff;
    }
    svg{
        color: #fff;
    }
}
.navbar-expand-lg .navbar-collapse {
padding-left: 10px;
}