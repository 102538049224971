.about{
    padding-top: 20px;
    padding-bottom: 60px;
    .header-about{
        margin-bottom:40px;
        h2{
            font-weight: 700;
            font-size: 35px;
        }
        p{
            font-weight: 600;
            color: #666;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            svg{
                color: #266562;
            }
        }
    }
    

    .top-box{
        margin-bottom: 30px !important;
        .box-top-image{
            background:  url('../../assits/header.jpg')  center ;
            background-size: cover;
            box-shadow: 2px 4px 10px rgba(201,201,201,0.47);
            width: 100%;
            height: 88%;
        }
        .title{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            p{
                color: gray;
                transition: 0.4s;
                font-weight: 600;
                padding: 5px;
                padding-top: 5px;
            }
        }
      }
.farm{
    padding-top:40px;
    padding-bottom: 40px;
    background:linear-gradient(rgba(239, 237, 237, 0.826),rgba(219, 216, 216, 0.863),rgba(227, 225, 225, 0.744),rgba(235, 233, 233, 0.767)),  url('../../assits/about-6.jpg')  center  fixed;
    h2{
        font-weight: 700;
        font-size: 35px;
        margin-bottom: 40px;
    }
    .farm-big-box{
        margin: auto !important;
        .farm-box{
            text-align: center;
            box-shadow: 2px 4px 10px rgba(201,201,201,0.47);
           height: 500px;
            width: 100%;
            border-radius: 10px;
            transition: 0.4s;
            margin-bottom: 30px;
            text-align: center;
            position: relative;
            padding-bottom: 40px;
            background-color: #fff;
            .box-img{
                width: 100%;
                height: 50%;
                margin-bottom: 18px;
                box-shadow: 2px 4px 10px rgba(201,201,201,0.47);
            img{
                            width: 100% ;
                            height: 100%;
                            
                        }
            }
          
            .icon{
                color: #266562;
                font-size: 50px;
                transition: 0.4s;
                position: absolute;
                top: 42%;
                color: #fff;
                border: 3px solid #fbfaf7;
                left: 37%;
                background-color:  #266562;
                padding: 10px;
                border-radius: 50%;
            }
            p{
                color: #266562;
                text-align: center;
                margin: auto;
                transition: 0.4s;
                font-weight: 400;
                padding: 5px;
                padding-top: 20px;
            }
       
            
        }
        .farm-box:hover{
            background-color: #266562;
            box-shadow: 0px 16px 32px 0px rgba(38,101,98,0.3);
            color: #f6f1f1;
            .icon{
                color: #266562;
                background-color: #fbfaf7;
                border: 3px solid #266562;
            }
            p{
                color: #f6f1f1;
            }
        }
    }
    .farm-two{
        background:linear-gradient(rgba(239, 237, 237, 0.826),rgba(219, 216, 216, 0.863),rgba(227, 225, 225, 0.744),rgba(235, 233, 233, 0.767)),  url('../../assits/about-1.jpg')  center ;
        background-size: cover;
        height: 80%;
        padding: 30px 20px 140px 20px;
        border-radius: 15px;
        h3{
            color: #99bb5d;
            font-size: 56px;
            font-weight: 700;
            letter-spacing: -2px;
        }
        p{
            color: #555;
            margin-bottom: 25px;
            font-weight: 500;
        }
        button{
            background-color: #2d8183;
                padding: 8px 25px;
                color:  #f6f1f1;
                border: none;
                border-radius: 8px;
                display: inline;
        }
    }
}
}