.contact-us{
    .font-contact-us{
        padding-top:40px ;
        padding-bottom:20px ;
        box-shadow: 2px 4px 10px rgba(201,201,201,0.47);
    }
    h2{
        font-weight: 700;
        font-size: 35px;
        margin-bottom: 40px;
    }
    input{
        // border: none;
        // border-bottom: 1px solid lightgray;
        width: 100%;
        margin-bottom: 30px;
        padding: 10px;
    }
    input:focus{
        outline: none !important;
    }
    .form-control:focus{
        border-color:none !important ;
        box-shadow: none !important;
    }
    .btn{
        width: 150px;
    }
    
}

.det-inppp{
 
    span{
        display: none;
        color: red;
        background-color: #eee;
        padding: 5px;
        border-radius: 5px;
        width : 90%;
        font-size: 13px;
    }
    p{
        color: red;
        background-color: #eee;
        padding: 5px;
        border-radius: 5px;
        width : 90%;
        font-size: 13px;
    }
    input:invalid[focused='true'] ~ span{
        display: block;
    
    }
    small{
        color: red;
    }}
.btn-primary{
    background-color:#266562 ;
    border-color:#266562 ;
}
.btn-primary:hover{
    background-color: #6ca742;
    color: #fff;
    border-color:#6ca742 ;
}